import Vue from 'vue';
import App from './app.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

window.snapSaveState = () => {
  document.querySelector('#app').setAttribute('data-server-rendered', 'true');
};
