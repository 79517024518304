<template lang='pug'>
#home
  #photo-gradient

  .content
    #title
      .hello Oh hey there!
      br
      .my-name I'm <b>Theo Ephraim</b>

    p.big
      | I'm a top-tier software engineer with serious chops across the entire startup "stack".
      br
      | I can build APIs, design products, write copy, push pixels, raise money, lead a team, and everything in between.
      br
      i.small Current interests -- Vue.js, Javascript, Typescript, Ethereum, UX, developer tooling

    section
      h2 Open-Source Projects
      p.section-intro
        | Throughout my career, the vast majority of my work has been on private projects.
        br
        | Even so, I managed to publish a few useful tools along the way - and many more are in the works!
      ul
        li
          | <a href='https://github.com/salsita/node-pg-migrate' target='_blank'>Node PG Migrate</a> - best Postgresql migration manager for node.js (44k weekly downloads)
        li
          | <a href='https://theoephraim.github.io/node-google-spreadsheet/#/' target='_blank'>Node Google Spreadsheet</a> - most popular js/ts module for google sheets API (130k weekly downloads)
        li
          | <a href='https://github.com/EthDevTools/ethdevtools' target='_blank'>ETH Dev Tools (WIP)</a> - chrome extension to add web3 debugging tools to the browser


    section
      h2 Work Experience
      p.section-intro
        | I've been doing freelance web dev since I was a kid, but here's some of my most relevant full-time experience:
      ul
        li <a href='https://systeminit.com/' target='_blank'>System Initiative</a> - Senior Software Engineer III
        li <a href='https://clay.com/' target='_blank'>Clay</a> - Senior Software Engineer
        li <a href='https://clear.co/' target='_blank'>Clearco / Clearbanc</a> - Lead Software Architect
        li <a href='https://breather.com/' target='_blank'>Breather</a> - First web engineer, Internal Tools Lead
        li <a href='https://www.busbud.com/' target='_blank'>Busbud</a> - Senior Eng
        li <a href='https://techcrunch.com/2012/01/25/highscore-house/' target='_blank'>HighScore House</a> - Co-founder, CTO, Product

    section.links
      h2 Get in touch!
      .clear
      a.icon-link(href='https://github.com/theoephraim' target='_blank' title='github')
        github-icon
      a.icon-link(href='https://www.linkedin.com/in/theo-ephraim-4891a741/' target='_blank' title='linkedin')
        linkedin-icon
      a.icon-link(href="mailto:theozero@gmail.com?subject=Let's work together" target='_blank' title='email')
        email-icon
      a.icon-link(href='https://t.me/theozero' target='_blank' title='telegram')
        telegram-icon

  #photo-wrap
    img#photo(src="../assets/breather-pic.jpg")


</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue';

export default {
  name: 'home',
  components: {
    'linkedin-icon': require('@/assets/linkedin.svg?inline').default,
    'github-icon': require('@/assets/github.svg?inline').default,
    'telegram-icon': require('@/assets/telegram.svg?inline').default,
    'email-icon': require('@/assets/email.svg?inline').default,
  },
};
</script>

<style lang="less">

@icon-size: 50px;
// @link-color: #13526d;
@link-color: #3c0570;
@black: #222;


@mq-small:  ~"only screen and (max-width: 767px)";
@mq-tablet-portrait: ~"only screen and (min-device-width: 768px) and (orientation: portrait)";

// @small-mobile:  ~"only screen and (max-width: 374px)";  // 375 is width of iphone 6
// // applies to mobile and small mobile
// @mobile:        ~"only screen and (max-width: 529px)";
// // @tablet:        ~"only screen and (min-width: 530px) and (max-width: 949px)";
// @desktop:        ~"only screen and (min-width: 530px)";
// // @desktop:       ~"only screen and (min-width: 950px) and (max-width: 1128px)";
// @desktop-xl:    ~"only screen and (min-width: 1129px)";


#home {
  border-top: 1px solid red;
  margin-top: -1px;
  text-align: left;
  font-size: 14px;
  line-height: 1.4em;
  height: 100%;

  @media @mq-small {
    background: url(../assets/gradient-horiz.png);
    background-repeat: repeat-y;
    background-size: 100% 1px;
  }
}


.content {
  padding: 3vw;
  padding-bottom: 2vw;
  margin-right: 67vh;
  z-index: 2;
  position: relative;

  @media @mq-small {
    padding: 20px;
    margin-right: 0;
  }
  @media @mq-tablet-portrait {
    margin-right: 28vh;
  }
}

#photo {
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;

  @media @mq-small {
    height: auto;
    width: 100%;
    position: relative;
    margin-bottom: -5px;
  }
  @media @mq-tablet-portrait {
    margin-right: -21vh;
  }
}
#photo-wrap {
  @media @mq-small {
    max-height: 100vw;
    overflow: hidden;
  }

}

.links {
  @media @mq-small {
    margin-bottom: -90px;
  }


  h2 {
    margin-right: 20px;
    clip-path: polygon(0 0px, 98% 10%, 100% 90%, 0 100%);
  }
  .clear {
    display: none;
    @media @mq-small {
      display: block;
    }
  }
  > a {
    display: inline-block;
    vertical-align: middle;
    color: @black;
    padding: 8px;
    margin-right: 10px;
    clip-path: polygon(0 0px, 100% 2%, 100% 100%, 0 97%);
  }
  .icon-link {
    width: @icon-size;
    height: @icon-size;
    > svg {
      fill: currentColor;
      height: 100%;
      width: 100%;
    }
  }
}

#title {
  margin-bottom: 2vw;
  > div {
    display: inline-block;
    line-height: 1.2em;
    background: @black;
    color: #FFF;
    padding: 13px 18px;
  }

  .hello {
    font-style: italic;
    font-size: 28px;
    padding-bottom: 1px;
    clip-path: polygon(0 0, 100% 12%, 100% 100%, 0 100%);
    @media @mq-small {
      font-size: 18px;
    }
  }
  .my-name {
    font-size: 42px;
    // padding-top: 5px;
    margin-top: -2px;
    text-transform: uppercase;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
    @media @mq-small {
      font-size: 28px;
    }
  }
}

a {
  color: #000;
  text-decoration: none;
  font-weight: bold;
  // transition: .2s all;
  padding: 1px 3px;
  &:hover {
    background: @black;
    color: white;
  }
}

#photo-gradient {
  background-image: url(../assets/gradient.png);
  background-repeat: repeat-x;
  background-size: 1px 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  @media @mq-small {
    display: none;
  }
}


section {
  margin: 1.5em 0;
  h2 {
    font-size: 14px;
    line-height: 1.2em;
    background: @black;
    color: #FFF;
    padding: 8px 12px 6px;
    display: inline-block;
    text-transform: uppercase;
    clip-path: polygon(2% 5%, 100% 0, 98% 100%, 0 100%);
  }
  .section-intro {
    margin-top: 0;
  }
  > ul {
    padding-left: 2em;
    margin-top: -4px;
    li { margin: 4px 0;}
  }

  &.links {
    margin-bottom: 0;
  }
}
p.big {
  font-size: 16px;
  line-height: 1.4em;
}
.small {
  font-size: 12px;
  line-height: 1.4em;
}


</style>
